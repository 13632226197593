<template>
  <div
    class="menu-item"
    :class="[
      {
        active:
          route.fullPath === item.link ||
          (item.link === PAGE_URLS.GUIDE &&
            (route.fullPath.includes(item.link) || route.fullPath.includes(PAGE_URLS.GUIDE))) ||
          (item.class === 'account' && route.fullPath.includes(item.link))
      },
      item.class
    ]"
    @click.prevent="openLink(item)"
  >
    <div class="menu-item__icon" :class="[{ logged: isLogged }]">
      <BaseImg :src="item.icon" class="img-fluid" />
    </div>
    <div class="d-block menu-item__text">
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { PAGE_URLS } from '~/config/page-url'
import useModal from '~/composables/useModal'
import { MODAL_TYPES } from '~/config/constant'

const { openModalWithNavigate } = useModal()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const router = useRouter()
const route = useRoute()

defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})

const openLink = (item: any) => {
  if (!isLogged.value && item.loginRequired) {
    return openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else {
    return router.push(item.link)
  }
}
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/menu-item.scss" />
