<template>
  <div id="tooltip-menu-bottom" class="wrapper-menu-bottom">
    <div id="menu-bottom" class="wrapper-menu-bottom__content">
      <MenuItem v-for="(item, index) in menus" :key="item.icon" :item="item" :class="`menu-item--${index}`" />
    </div>
  </div>
</template>
<script setup lang="ts">
import MenuItem from './menu-item.vue'
import { PAGE_URLS } from '~/config/page-url'
import { BOTTOM_MENU } from '~/constants/menu'

const route = useRoute()
const menus = computed(() => {
  const isPromotionRoute =
    route.path.startsWith(PAGE_URLS.GAME) || route.path === PAGE_URLS.SPORT || route.path === PAGE_URLS.CASINO

  return BOTTOM_MENU.filter((item) => {
    if (route.path === PAGE_URLS.HOME) {
      return item.name !== 'Trang chủ'
    }

    if (route.path === PAGE_URLS.PROMOTION) {
      return item.name !== 'Khuyến mãi'
    }

    if (isPromotionRoute) {
      return item.name !== 'Trang chủ'
    }

    return item.name !== 'Khuyến mãi'
  })
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/index.scss" />
