<template>
  <div class="banner-item" @click="handleClick()">
    <BaseImg :src="item.img" :alt="item?.name" lazy />
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/banner/banner-item.scss" />
