<template>
  <MobileLayoutsHome>
    <template #main>
      <div class="layout-game" :style="{ height: `${height}px` }">
        <div class="game-wrapper">
          <div class="game-wrapper__content" :class="{ 'margin-item': marginItem }">
            <div
              id="target_element"
              class="game-wrapper__content--left"
              :style="{
                height: route.fullPath === '/the-thao' ? 'auto' : '100%'
              }"
            >
              <div ref="listRef" class="group-tab">
                <nuxt-link
                  v-for="tab in TABS"
                  ref="itemRef"
                  :key="tab.key"
                  class="tab-link"
                  :to="tab.key"
                  :class="[
                    tab.key,
                    {
                      active: isActive(tab.key, tab.active),
                      updateAnimation: tab.key === '/game/game-nhanh' || tab.key === '/game/table-game'
                    }
                  ]"
                >
                  <BaseImg
                    :src="
                      tab.key === route.fullPath || (tab.key === 'game/game-hot' && route.fullPath)
                        ? tab.iconImage
                        : tab.iconImageDefault
                    "
                    :alt="tab.title"
                  />
                  <div>{{ tab.title }}</div>
                </nuxt-link>
              </div>
            </div>
            <div ref="listRightRef" class="game-wrapper__content--right">
              <nuxt-page />
            </div>
          </div>
        </div>
      </div>
    </template>
  </MobileLayoutsHome>
</template>

<script setup lang="ts">
import { getLeftMenu } from '~/resources/menu-left'
import MobileLayoutsHome from '~/components/mobile/layouts/home.vue'
import { isValidDynamicUrls, isValidLivecasinoUrl } from '~~/helper'

const route = useRoute()
const router = useRouter()
const { TABS } = getLeftMenu()
const listRef = ref(null)
const itemRef = ref<(HTMLElement | null)[]>([])
const listRightRef = ref(null)
const marginItem = ref(false)
const { $device } = useNuxtApp()

const height = ref<number>()

const getHeightContent = async (): Promise<number> => {
  const getHeaderElement = (await document.getElementById('header-mobile')?.clientHeight) ?? 48
  const getHeroBannerElement = (await document.getElementById('hero-banner')?.clientHeight) ?? 128
  const getMenuBottomElement =
    (await document.querySelectorAll('#tooltip-menu-bottom > #menu-bottom')[0]?.clientHeight) ?? 60

  const androidHeight = await (window.innerHeight - (getHeroBannerElement + getHeaderElement + getMenuBottomElement))
  const iOSHeight = await (document.documentElement.clientHeight -
    (getHeroBannerElement + getHeaderElement + getMenuBottomElement))

  return $device.isIos ? iOSHeight : androidHeight
}

onMounted(async () => {
  height.value = await getHeightContent()
  if (isIOS()) {
    const bodyElement = await document.querySelector('body')
    if (bodyElement !== null) {
      bodyElement.classList.add('fix-screen')
    }
  }
  window.addEventListener('resize', async () => {
    height.value = await getHeightContent()
  })
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', () => {})
  if (isIOS()) {
    const bodyElement = document.querySelector('body')
    if (bodyElement !== null) {
      bodyElement.classList.remove('fix-screen')
    }
  }
})

const scrollToActive = (delay = 50, index: number) => {
  setTimeout(() => {
    const itemRef = document?.querySelectorAll('.tab-link')
    if (itemRef) {
      const item = itemRef[index] as HTMLElement | null
      if (item) {
        item.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }, delay)
}

watch(
  route,
  () => {
    TABS.find((e) => {
      const tab = findTabByRoute(e)
      if (tab) {
        scrollToActive(150, tab)
        return true
      }
      return false
    })
  },
  {
    immediate: true
  }
)

function findTabByRoute(tab: typeof TABS[number]) {
  const { name, path } = route

  const validNames = ['the-thao', 'livecasino', 'game-type']

  if (typeof name === 'string' && validNames.includes(name) && tab.key === path) {
    return tab.index - 1
  }
  return undefined
}

const handleRedirectPage = (tab: any) => {
  router.push(tab.key)
}

const isIOS = () => {
  const ua = navigator?.userAgent
  return /iPhone|iPad|iPod/i.test(ua)
}

const isActive = (keyTab: string, active?: string) => {
  const { fullPath } = route
  const validateGameUrl = isValidDynamicUrls(fullPath, keyTab)
  const validateLivecasinoUrl = active === 'livecasino' && isValidLivecasinoUrl(fullPath)

  if (validateGameUrl || validateLivecasinoUrl || keyTab === fullPath || (keyTab === 'game/game-hot' && !fullPath)) {
    return true
  }
  return false
}
</script>

<style lang="scss" scoped src="~/assets/scss/components/mobile/layouts/layout-game.scss"></style>
