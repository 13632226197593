<template>
  <div id="header-mobile" class="header">
    <CommonBaseImg
      id="logo-mobile"
      class="logo"
      :src="`${PATH_BRAND}logo.webp`"
      alt="logo"
      @click="$router.push(PAGE_URLS.HOME)"
    />
    <div class="header-button">
      <MobileHeaderLoginButton v-if="!currentUser" />
      <MobileHeaderUserLogged v-else />
    </div>
  </div>
</template>
<script setup lang="ts">
import { PATH_BRAND } from '~/constants/path'
import CommonBaseImg from '~/components/common/base-img'
import MobileHeaderLoginButton from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { useHeader } from '~/composables/useHeader'
import { PAGE_URLS } from '~/config/page-url'
const { currentUser } = useHeader()
const $router = useRouter()
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/index.scss" />
